<template>
  <v-container>
    <loading-box v-if="MachineServiceHistories == null" :loading="true" />

    <v-container v-else class="pa-0">
      <h4>
        <span v-if="openJobs && openJobs.length > 0">{{
      $t("text.openjobs")
    }}</span>
        <v-btn color="primary" v-if="openJobs && openJobs.length == 0" @click="newjobdialog = true">{{
      $t("text.orderservice") }}</v-btn>
      </h4>

      <new-job-dialog :machine="machine" :machines="[machine]" v-if="newjobdialog" @exit="closeNewJobDialog" />

      <v-sheet v-for="item in openJobs" :key="item.Id" class="ma-5 mb-5">
        <v-card :rounded="false" class="text-left">
          <v-toolbar color="primary" dark dense elevation="1" class="mb-3">
            <v-toolbar-title>
              {{ item.DisplayName }}
            </v-toolbar-title>
            <v-spacer />
            {{ item.JobTypeName }}
          </v-toolbar>

          <v-card-subtitle class="mt-0 pt-0 mb-0 pb-0">
            {{ $t("text.registered") }}:
            {{ new Date(item.CreatedDate).toLocaleDateString() }}
          </v-card-subtitle>
          <v-card-subtitle class="mt-0 pt-0 mb-0 pb-0">{{ $t("text.status") }}: {{ item.StatusName }}
          </v-card-subtitle>
          <v-card-text>{{ $t("text.error_description") }}:
            {{ item.CustomerComment }}</v-card-text>
          <v-card-text v-if="item.InvoiceComment">{{ $t("text.comment") }}: {{ item.InvoiceComment }}</v-card-text>
          <v-data-table :headers="partsHeader" :items="item.MachineServiceHistoriesParts" disable-filtering
            disable-pagination disable-sort hide-default-footer v-if="item.MachineServiceHistoriesParts &&
      item.MachineServiceHistoriesParts.length > 0
      ">
            <template v-slot:top>
              <v-divider />
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" outlined @click="openJob(item)">
              {{ $t("text.open") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-sheet>
      <v-divider class="my-2" />
      <h4>{{ $t("text.history") }}</h4>
      <v-sheet v-for="item in closedJobs" :key="item.Id" class="ma-5 mb-5">
        <v-card :rounded="false" class="text-left">
          <v-card-title>
            {{ item.Job.DisplayName }}
            <v-spacer />
            {{ new Date(item.CreatedDate).toLocaleDateString() }}
          </v-card-title>
          <v-card-subtitle>{{ item.Job.StatusName }}</v-card-subtitle>
          <v-card-text>{{ $t("text.error_description") }}:
            {{ item.Job.CustomerComment }}</v-card-text>
          <v-card-text>{{ $t("text.comment") }}:
            {{ item.Job.InvoiceComment }}</v-card-text>
          <v-card-text v-if="item.MachineServiceHistoriesParts">
            <v-divider />
            <v-data-table :headers="partsHeader" :items="item.MachineServiceHistoriesParts" disable-filtering
              disable-pagination disable-sort hide-default-footer></v-data-table>
          </v-card-text>
          <v-card-actions v-if="item.ServiceReport">
            <v-spacer />
            <v-btn color="primary" :href="'/api2/resource?relativePath=' + encodeURI(item.ServiceReport)">
              <v-icon>mdi-file-pdf-box</v-icon>
              <v-divider vertical class="mx-1" />
              {{ $t("text.service_report") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-sheet>
    </v-container>
    <JobDialog></JobDialog>
    <v-snackbar v-model="snackbar" color="primary">
      {{ snackbarMessage }}
    </v-snackbar>
  </v-container>
</template>


<script>
import http from "@/api/client";
import JobDialog from "@/components/Dialogs/JobDialog";
import NewJobDialog from "@/components/Dialogs/NewJobDialogEx.vue";
import LoadingBox from "@/components/LoadingBox.vue";

export default {
  name: "ServicePanel",
  components: {
    "loading-box": LoadingBox,
    NewJobDialog,
    JobDialog,
  },

  data() {
    return {
      snackbar: false,
      snackbarMessage: null,
      openJobs: null,
      machineSelection: null,
      loading: false,
      newjobdialog: false,
      partsHeader: [
        {
          text: this.$t("text.article"),
          value: "Article.ArticleDescription",
        },
        {
          text: this.$t("text.amount"),
          value: "ArticleQtyOrdered",
        },
        {
          text: this.$t("text.price"),
          value: "ArticlePriceOut",
        },
      ],
      selectedJob: null, //Preinitialize
      machine: null,
      MachineServiceHistories: null,
    };
  },

  mounted() {
    this.init();
    if (this.machine) this.updateServiceHistories(this.machine);
    else this.$emit("updateMachine");
  },

  methods: {
    closeNewJobDialog(message) {
      this.newjobdialog = false;
      this.snackbarMessage = message;
      this.snackbar = true;
      this.updateServiceHistories(this.machine);
    },
    updateServiceHistories(machine) {
      if (!machine) return;
      this.loading = true;
      http()
        .get("api2/customer/machines/history/" + machine.Id)
        .then((response) => {
          this.MachineServiceHistories = response.data;

          if (this.closedJobs && this.closedJobs.length > 0)
            this.selectedJob = this.closedJobs[0];
          this.loading = false;
        });

      http()
        .get("api2/customer/jobs/openmachinejobs/" + machine.Id)
        .then((response) => {
          this.openJobs = response.data;
        });
    },

    init() {
      this.machine = this.$route.params.Machine;
    },

    openJob(item) {
      this.$router.push({ params: { jobId: item.Id } });
    }
  },

  computed: {
    closedJobs() {
      if (
        this.MachineServiceHistories != null &&
        this.MachineServiceHistories.length > 0
      ) {
        return this.MachineServiceHistories.filter(
          (e) => e.Job?.StatusId > 30 && e.Job?.StatusId <= 100
        ).sort((a, b) => b.Id - a.Id);
      } else {
        return null;
      }
    },
    /*openJobs() {
      if (
        this.MachineServiceHistories != null &&
        this.MachineServiceHistories.length > 0
      ) {
        return this.MachineServiceHistories.filter(
          (e) => e.Job.StatusId <= 30
        ).sort((a, b) => b.Id - a.Id);
      } else {
        return null;
      }
    },*/
  },

  watch: {
    machine(val) {
      this.updateServiceHistories(val);
    },
    $route() {
      this.init();
    },
  },
};
</script>


<style scoped>
.highlighted {
  background-color: var(--v-secondary-base) !important;
}
</style>
